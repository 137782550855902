import React, { useEffect, useRef, useLayoutEffect, useState, useCallback } from 'react';
import loadFonts from '../../helpers/FontFunFunction';

import { catcchErrorHandling, ecqFormatting, currentChangeAndLocalMenu } from '../../helpers';

import ErrorBox from '../../component/ErrorBox';

import Board from '../../component/Board';
import PillowSlide from '../../component/PillowSlide';
import ContactSlide from '../../component/PillowSlide/ContactSlide';

import ColorPicker from '../../component/ColorPicker/ColorPicker';
import BackgroundImage from '../../component/BackgroundImage';
import BackgroundSelect from '../../component/BackgroundImage/BackgroundSelect';
import Editable from '../../component/Editable';
import EmailShare from '../../component/EmailShare';
import Message from '../../component/Message';

import { PresentationPropsType } from './typePresentation';

import lookandFeelContext from '../../context/LookAndFeelContext';
import lookContext from '../../context/LookContext';
import settingContext from '../../context/SettingContext';
import boardContext from '../../context/BoardContext';
import EditEcqContext from '../../context/EditEcqContext';
import ThemeContext from '../../context/ThemeContext';
import languageContext from '../../context/languageContext';
import LayoutContext from '../../context/LayoutContext';
import MessageContext from '../../context/MessageContext';

// APIs
import { deleteColor } from '../../api/colorSchemes/deleteColor';
import { saveColor } from '../../api/colorSchemes/postSaveColor';
import { deleteBackgroundImages } from '../../api/deleteBackgroundImages/deleteBackgroundImages';
import { getUserPresentationDetail } from '../../api/getUserPresentationDetail/getUserPresentationDetail';
import { getAllTips } from '../../api/getAllTips/getAllTips';
import { getThemes } from '../../api/getThemes/getThemes';
import { getglobalSetting } from '../../api/getGlobalSetting/getGlobalSetting';
import { getProductTemplate } from '../../api/getProductTemplate/getProductTemplate';
import { getBackgroundImages } from '../../api/getBackgroundImages/getBackgroundImages';
// import { saveDefaultTheme } from '../../api/postSaveDefaultTheme/postSaveDefaultTheme';
import { putPresentationUpdate } from '../../api/putPresentationUpdate/putPresentationUpdate';
import { postSaveLookAndFeel } from '../../api/postSaveLookAndFeel/postSaveLookAndFeel';
import { postSaveCustomerLogoInfo } from '../../api/postSaveCustomerLogoInfo/postSaveCustomerLogoInfo';
import { postSavePresentationLogoInfo } from '../../api/postSavePresentationLogoInfo/postSavePresentationLogoInfo';
import { deleteRemoveSlide } from '../../api/deleteRemoveSlide/deleteRemoveSlide';
import { postChangeSlideVisibility } from '../../api/postChangeSlideVisibility/postChangeSlideVisibility';
import { postUpdateTitle } from '../../api/postUpdateTitle/postUpdateTitle';
import { postImageUpload } from '../../api/postImageUpload/postImageUpload';
import { postPDF } from '../../api/postPDF/postPDF';
import { postProductDesignInfo } from '../../api/postProductDesignInfo/postProductDesignInfo';
import { postLoadProductTemplate } from '../../api/postLoadProductTemplate/postLoadProductTemplate';
import { postReset } from '../../api/postReset/postReset';
import { postEcqupdate } from '../../api/postEcqupdate/postEcqupdate';
import { postLoadDefaultTheme } from '../../api/postLoadDefaultTheme/postLoadDefaultTheme';
import { postProductElementInfo } from '../../api/postProductElementInfo/postProductElementInfo';
// import { postPTT } from '../../api/postPTT/postPTT';
import { postSwitchTheme } from '../../api/postSwitchTheme/postSwitchTheme';
import { postUpdateSlideProperty } from '../../api/postUpdateSlideProperty/postUpdateSlideProperty';
import { postAddImageSlide } from '../../api/postAddImageSlide/postAddImageSlide';
import { putToggleColor } from '../../api/putToggleColor/putToggleColor';

import TitleBar from '../../component/FullScreen/TitleBar/TitleBar';
import SideBar from '../../component/FullScreen/SideBar/SideBar';
import ActionBar from '../../component/FullScreen/ActionBar/ActionBar';
import Slides from '../../component/FullScreen/Slides/Slides';
import MenuProvider from '../../context/MenuContext';
import Loader from '../../component/Loader/Loader';
import '../container.css';

// const PillowSlide = React.lazy(() => import('../../component/PillowSlide'));
// const ContactSlide = React.lazy(() => import('../../component/PillowSlide/ContactSlide'));

const Presentation = (props: PresentationPropsType) => {
    const [firstload, setfirstload] = useState(false);
    const [thumbload, setthumbload] = useState(false);
    const { display, setDisplayOverview } = props;
    const { baseUrl, clientID, validationToken, type } = props;
    const [typeName, setTypeName] = useState('');
    const [extraKey] = useState(props.extraKey);
    const [accesskey, setaccesskey] = useState(props.accesskey);
    const [fonts, setfonts] = useState(props.fonts);
    const timer = 3000;
    // * LOADER DISPLAY
    const [isLoading, setLoading] = useState(0);
    const [renderCount, setrenderCount] = useState(0);
    // * BREAD
    const [bread, setbread] = useState([]);
    // * ECQ
    const [ecqView, setECQView] = useState(false);
    const [ecqEditData, setECQeditaData] = useState([]);
    const [ecqDefaultData, setECQDefaultData] = useState([]);
    // * THEMES
    const [themes, setthemes] = useState([]);
    const [activetheme, setactivetheme] = useState('');
    // * SETTING DISPLAY
    const [localsettingcopy, setlocalsettingcopy] = useState(props.setting);
    const [settingView, setSettingView] = useState(false);
    const [colorpickview, setColorpickview] = useState(false);
    const [backgroundView, setBackgroundview] = useState(false);
    const [backgroundUpload, setBackgroundUpload] = useState(false);
    const [backgroundImageList, setBackgroundImageList] = useState([]);
    const [backgroundSelectOption, setbackgroundSelectOption] = useState('option');
    const [, setLocalmenu] = useState(false);
    const [localEdit, setLocalEdit] = useState<any>({});
    const [look, setLook] = useState({});
    const [template, setTemplate] = useState([]);
    const [element, setElement] = useState<any>([]);
    //SHAPE AND COLORS
    const [colorScheme, setColorScheme] = useState<any>([]);
    const [activeColor, setActiveColor] = useState({
        _id: 0,
        accent1: '#ffffff',
        accent2: '#ffffff',
        main: '#ffffff',
        name: 'init',
    });
    const [isPredefineColor, setIsPredefineColor] = useState(false);
    const [customColor, setCustomColor] = useState<any>([]);
    const [isCustomColor, setIsCustomColor] = useState(false);

    // WIP Pillow
    const [design, setDesign] = useState([]);
    const [margin, setMargin] = useState(0);
    const [current, setCurrent] = useState<any>(null);
    const [presentationFont, setPresentationFont] = useState('');
    const [presentationBg, setPresentationBg] = useState('');
    const [presentationTitle, setPresentationTitle] = useState('');
    const [presentationLogo, setPresentationLogo] = useState({});
    const [editorLogo, setEditorLogo] = useState({});
    const [bgActive, setBgActive] = useState({});
    const [presentationId, setPresentationId] = useState<null | number>(null);
    //* hook add to know the template seletec
    const [templateId, setTemplateId] = useState('');
    const [activeSlide, setActiveSlide] = useState<null | number>(null);
    const [error, setError] = useState('');
    const [sslides, setSlides] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [pdfCreating, setPdfCreating] = useState(false);
    const [emailshareflag, setemailShareFlag] = useState(false);
    const [pdfcopylink, setpdfcopylink] = useState('');
    const [pdfFile, setPdfFile] = useState('');
    const [language, setlanguage] = useState({});
    const [contactID, setContactID] = useState('');
    const [imageSlide2, setImageSlide2] = useState<any>(null);
    const [tips, setTips] = useState<any | null>(null);
    const [logoColorList, SetLogoColorList] = useState<string[] | null>(null);

    // TRACK SCREEN CHANGE, MAIN SECTION REF
    const mainRef = useRef<HTMLDivElement>(null);
    const slideRef = useRef<HTMLDivElement>(null);

    const fetchThemes = useCallback(async () => {
        try {
            const { accesskey }: any = await validationToken(props.type);
            const { status, data }: any = await getThemes(baseUrl, clientID, extraKey, accesskey, props.type);

            if (status >= 200 && status < 300) {
                setthemes(data);
            } else {
                setError(`Unexpected status code: ${status}`);
            }
        } catch (err: any) {
            const msg = catcchErrorHandling(err);
            setError(msg);
        }
    }, [props.type, baseUrl, clientID, extraKey, validationToken]); // Add other dependencies if needed

    useEffect(() => {
        if (slideRef.current !== null) {
            slideRef.current.addEventListener('resize', () => {
                console.log(slideRef.current);
            });
        }
        fetchThemes();

        // Add styles to the body when component mounts
        document.body.style.overflow = 'hidden'; // Example style
        // Clean up function to remove styles when component unmounts
        return () => {
            document.body.style.overflow = ''; // Reset to default
        };
    }, [fetchThemes]);

    const [dim, setDim] = useState<any>({
        width: 0,
        height: 0,
        scale: 0.1,
        sidemenu: false,
        slidemenu: false,
    });

    const mv = { width: 925, height: 525 };
    /* const handleResize = () => {
        if (mainRef.current) {
            const { width, height } = mainRef.current.getBoundingClientRect();
            const ratioWidth = ((width - 60) / mv.width).toFixed(2);
            const ratioHeight = ((height - 60) / mv.height).toFixed(2);
            const scale = ratioWidth > ratioHeight ? ratioHeight : ratioWidth;
            setDim((old: any) => ({ ...old, scale: parseFloat(scale) > 0.5 ? scale : 0.5 }));
        }
    }; */
    const handleResize = useCallback(() => {
        if (mainRef.current) {
            const { width, height } = mainRef.current.getBoundingClientRect();
            const ratioWidth = ((width - 60) / mv.width).toFixed(2);
            const ratioHeight = ((height - 60) / mv.height).toFixed(2);
            const scale = ratioWidth > ratioHeight ? ratioHeight : ratioWidth;
            setDim((old: any) => ({ ...old, scale: parseFloat(scale) > 0.5 ? scale : 0.5 }));
        }
    }, [mv.width, mv.height]);

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        }, 100);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [mainRef, handleResize]);

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        }, 300);
    }, [dim.sidemenu, dim.slidemenu, handleResize]);

    // MESSAGE DISPLAY COMPONENT
    const [message, setMessage] = useState<any>({
        display: false,
        type: 'normal',
        message: 'dummy message!',
        fn: () => null,
    });

    const pdferrormsg = "PDF can't be created.";

    const changeCurrent = useCallback(
        (target: any, flag: any = false) => {
            currentChangeAndLocalMenu({ target, localEdit, setCurrent, setLocalEdit, flag });
        },
        [localEdit]
    );

    useEffect(() => setaccesskey(props.accesskey), [props.accesskey]);
    useEffect(() => thumbGenerator(false, 500), [renderCount]);

    const fetchData = useCallback(
        (presentationID: any, access: any) => {
            setLoading((old) => old + 1);
            const acc = access !== undefined ? access : accesskey;
            const accessToken: any = validationToken(type);
            getUserPresentationDetail(baseUrl, presentationID, clientID, extraKey, acc)
                .then((response: any) => {
                    const { status, slides, productReferenceData, prodDefault, translation, allColorScheme, ...resp } =
                        response;
                    if (status >= 400) {
                        setError('Bad response from server');
                        return null;
                    }
                    setlanguage(translation);
                    const {
                        presentation_background = '#fff',
                        presentation_display_name,
                        presentation_logo,
                        end_customer_logo,
                        look_and_feel,
                        _id,
                        product_template_id,
                        product_slide_elements = [],
                        product_slide_design = [],
                        theme_id = '',
                        presentation_type_id,
                        margin,
                        contact_id,
                        isPredefinedColor,
                        isCustomColor = false,
                        activeColorId = '',
                        logoColor = null,
                        isCoverImageActive = false,
                        isCorporateColorActive = false,
                        corporateColorId = '',
                    } = resp;

                    if (logoColor !== null && logoColor.length > 0) {
                        SetLogoColorList(logoColor);
                    }

                    if (allColorScheme !== undefined && allColorScheme.length > 0) {
                        let activeColorFlagCondition = true;

                        allColorScheme.map((list: any) => {
                            if (list.type === 'Global') {
                                setColorScheme(list.colors);
                                if (activeColorId !== '') {
                                    const activeColor = list.colors.filter((col: any) => col._id === activeColorId);
                                    if (activeColor.length === 1) {
                                        setActiveColor(activeColor[0]);
                                    }
                                } else if (isCorporateColorActive === true) {
                                    const activeColor = list.colors.filter((col: any) => {
                                        return col._id === corporateColorId;
                                    });
                                    if (activeColor.length === 1) {
                                        setActiveColor(activeColor[0]);
                                    }
                                } else {
                                    setActiveColor(list.colors[0]);
                                }
                            }
                            if (list.type === 'Custom') {
                                setCustomColor(list.colors);
                                if (activeColorId !== '') {
                                    const activeColor = list.colors.filter((col: any) => col._id === activeColorId);
                                    if (activeColor.length === 1) {
                                        setActiveColor(activeColor[0]);
                                    }
                                } else if (isCorporateColorActive === true) {
                                    const activeColor = list.colors.filter((col: any) => {
                                        return col._id === corporateColorId;
                                    });
                                    if (activeColor.length === 1) {
                                        setActiveColor(activeColor[0]);
                                    } else {
                                        activeColorFlagCondition = false;
                                    }
                                } else setActiveColor(list.colors[0]);
                            }
                            return null;
                        });
                        if (!activeColorFlagCondition) {
                            setIsPredefineColor(false);
                            setIsCustomColor(false);
                        } else {
                            setIsPredefineColor(isPredefinedColor);
                            setIsCustomColor(isCustomColor);
                        }
                    }

                    if (resp.type !== undefined) {
                        setTypeName(resp.type);
                    }

                    setactivetheme(theme_id);
                    setContactID(contact_id);

                    setElement(product_slide_elements);
                    setDesign(product_slide_design);

                    setTemplateId(product_template_id);
                    setPresentationId(_id);
                    if (look_and_feel !== undefined && look_and_feel.font_name !== undefined) {
                        setPresentationFont(look_and_feel.font_name);
                    }
                    setPresentationBg(`#${presentation_background}`);
                    setPresentationTitle(presentation_display_name);
                    setPresentationLogo({
                        ...presentation_logo,
                        name: 'Show Debtor logo',
                    });
                    setEditorLogo({
                        ...end_customer_logo,
                        name: 'Show End customer logo',
                    });
                    setLook(look_and_feel);

                    /*
                     * ? NOTE
                     * CREATING NEW SLIDES AFTER FETCH
                     * 1. END CUSTOMER LOGO BLINKS IN PRODUCT_SLIDE WITH PHOTO_LAYOUT
                     * 2. UN USED ELEMENTS REMOVE
                     */
                    const updateSlide = slides.map((ss: any) => {
                        // console.log(ss);
                        const { type, product_template_type, elements } = ss;
                        if (product_template_type === 'photo_layout' && type === 'product_slide') {
                            const newele: any = [];
                            elements.map((ele: any) => {
                                if (
                                    ele.element_id === 'end_customer_logo' ||
                                    ele.element_id === 'end_customer_logo_background'
                                ) {
                                    return null;
                                }
                                newele.push(ele);
                                return null;
                            });
                            return { ...ss, elements: newele };
                        }
                        return ss;
                    });
                    // setSlides(slides);
                    setSlides(updateSlide);
                    if (margin !== undefined && margin !== '') {
                        setMargin(margin);
                    }

                    // ! data for reset
                    if (prodDefault !== undefined && prodDefault.length > 0) {
                        const ecqDataManipulate = ecqFormatting(prodDefault);
                        setECQDefaultData(ecqDataManipulate);
                    }
                    // ! data for display
                    if (productReferenceData !== undefined && productReferenceData.length > 0) {
                        const newResetEcqData = ecqFormatting(productReferenceData);
                        setECQeditaData(newResetEcqData);
                        /*
                         * reference data of product for catalog product slide
                         * to display product image options in pop up
                         */
                        setProductImages(productReferenceData);
                    }

                    if (slides !== undefined && slides.length > 0) {
                        setActiveSlide(0);
                        changeCurrent(slides[0]);
                        // setCurrent(slides[0]);
                    }

                    accessToken.then(({ accesskey }: any) => {
                        getglobalSetting(baseUrl, clientID, extraKey, accesskey, {
                            theme_id,
                            presentation_type: presentation_type_id,
                        })
                            .then(({ status, data }) => {
                                if (status >= 200 && status < 300) {
                                    const { global_menu = [], fonts = [] } = data;
                                    const setting: any = {};
                                    if (global_menu.length > 0) {
                                        global_menu.map((res: any) => {
                                            const { name } = res;
                                            if (isCoverImageActive === true && name === 'themes') {
                                                setting[name] = { ...res, visible: false };
                                            } else setting[name] = res;

                                            return null;
                                        });
                                    }
                                    setfonts(fonts);
                                    setlocalsettingcopy(setting);
                                }
                            })
                            .catch((err: any) => {
                                setLoading(0);
                                const msg = catcchErrorHandling(err);
                                setError(msg);
                            });
                    });
                    setLoading((old) => {
                        return old - 1;
                    });
                    handleResize();
                    // setLoading(0);
                })
                .catch((err: any) => {
                    setLoading((old) => old - 1);
                    // setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        },
        [baseUrl, clientID, extraKey, accesskey, type, changeCurrent, validationToken, handleResize]
    );

    useEffect(() => {
        if (!firstload) {
            const { presentationID, extra, type, old_customer_id } = props;
            const accessToken: any = validationToken(type);
            if (presentationID !== null) {
                accessToken.then(({ accesskey }: any) => {
                    setPresentationId(presentationID);
                    fetchData(presentationID, accesskey);
                });
            } else {
                const {
                    decorator_logo = '',
                    end_customer_logo = '',
                    presentation_title: title,
                    products,
                    reference_id,
                    contact,
                    theme,
                    storeview,
                    margin,
                    email,
                    price_format = '',
                    logocolor = [],
                    // presentation_logo
                } = extra;
                const obj = {
                    presentation_type: type,
                    end_customer_logo,
                    presentation_logo: decorator_logo,
                    title,
                    products,
                    reference_id,
                    contact,
                    theme,
                    storeview,
                    margin,
                    email,
                    old_customer_id,
                    price_format,
                    logocolor,
                };
                // fetchLoadDefault(obj);
                // console.log('extra', extra)
                // console.log('obj', obj)
                setLoading(1);
                accessToken.then(({ accesskey }: any) => {
                    postLoadDefaultTheme(baseUrl, clientID, extraKey, accesskey, obj)
                        .then(
                            ({
                                status,
                                slides,
                                productReferenceData,
                                prodDefault,
                                translation,
                                allColorScheme,
                                ...resp
                            }: any) => {
                                if (status >= 400) {
                                    setError('Bad response from server');
                                    return null;
                                }
                                setlanguage(translation);
                                if (resp.presentation_type_title !== '') document.title = resp.presentation_type_title;
                                const {
                                    presentation_background,
                                    presentation_display_name,
                                    presentation_logo,
                                    end_customer_logo,
                                    look_and_feel,
                                    _id,
                                    product_template_id,
                                    product_slide_elements = [],
                                    product_slide_design = [],
                                    theme_id = '',
                                    margin,
                                    contact_id,
                                    isPredefinedColor,
                                    isCustomColor,
                                    activeColorId = '',
                                    logoColor = null,
                                    isCorporateColorActive = false,
                                    isCoverImageActive = false,
                                    corporateColorId = '',
                                } = resp;

                                if (isCoverImageActive === true) {
                                    setlocalsettingcopy({
                                        ...localsettingcopy,
                                        themes: { ...localsettingcopy.themes, visible: false },
                                    });
                                }
                                if (logoColor !== null) {
                                    SetLogoColorList(logoColor);
                                }
                                if (allColorScheme.length > 0) {
                                    allColorScheme.map((list: any) => {
                                        if (list.type === 'Global') {
                                            setColorScheme(list.colors);
                                            if (activeColorId !== '' && isCorporateColorActive === false) {
                                                const activeColor = list.colors.filter(
                                                    (col: any) => col._id === activeColorId
                                                );
                                                if (activeColor.length === 1) {
                                                    setActiveColor(activeColor[0]);
                                                }
                                            } else setActiveColor(list.colors[0]);
                                        }
                                        if (list.type === 'Custom') {
                                            setCustomColor(list.colors);
                                            if (activeColorId !== '' && isCorporateColorActive === false) {
                                                const activeColor = list.colors.filter(
                                                    (col: any) => col._id === activeColorId
                                                );
                                                if (activeColor.length === 1) {
                                                    setActiveColor(activeColor[0]);
                                                }
                                            } else if (isCorporateColorActive === true) {
                                                const activeColor = list.colors.filter((col: any) => {
                                                    return col._id === corporateColorId;
                                                });
                                                if (activeColor.length === 1) {
                                                    setActiveColor(activeColor[0]);
                                                }
                                            } else setActiveColor(list.colors[0]);
                                        }
                                        return null;
                                    });
                                    // setColorScheme(allColorScheme);
                                    // setActiveColor(allColorScheme[0]);
                                    setIsPredefineColor(isPredefinedColor);
                                    setIsCustomColor(isCustomColor);
                                }
                                if (resp.type !== '') {
                                    setTypeName(resp.type);
                                }

                                setactivetheme(theme_id);
                                setContactID(contact_id);

                                // PILLOW
                                setElement(product_slide_elements);
                                setDesign(product_slide_design);

                                setTemplateId(product_template_id);
                                setPresentationId(_id);
                                if (look_and_feel !== undefined && look_and_feel.font_name !== '') {
                                    setPresentationFont(look_and_feel.font_name);
                                }
                                if (margin !== '') {
                                    setMargin(margin);
                                }
                                setPresentationBg(`#${presentation_background}`);
                                setPresentationTitle(presentation_display_name);
                                setPresentationLogo({
                                    ...presentation_logo,
                                    name: 'Show Debtor logo',
                                });
                                setEditorLogo({
                                    ...end_customer_logo,
                                    name: 'Show End customer logo',
                                });
                                setLook(look_and_feel);
                                setSlides(slides);

                                /*
                                 * slide in array
                                 * reset data should be in array
                                 */
                                // ! data for reset
                                if (prodDefault !== undefined && prodDefault.length > 0) {
                                    const ecqDataManipulate = ecqFormatting(prodDefault);
                                    setECQDefaultData(ecqDataManipulate);
                                }
                                // ! data for display
                                if (productReferenceData !== undefined && productReferenceData.length > 0) {
                                    const newResetEcqData = ecqFormatting(productReferenceData);
                                    setECQeditaData(newResetEcqData);
                                    /*
                                     * reference data of product for catalog product slide
                                     * to display product image options in pop up
                                     */
                                    setProductImages(productReferenceData);
                                }

                                if (slides !== undefined && slides.length > 0) {
                                    setActiveSlide(0);
                                    changeCurrent(slides[0]);
                                }
                                setLoading(0);
                            }
                        )
                        .catch((err: any) => {
                            setLoading(0);
                            const msg = catcchErrorHandling(err);
                            setError(msg);
                        });
                });
            }
            // fetchProductTemplate(type);
            setfirstload(true);
        }
    }, [
        props,
        baseUrl,
        clientID,
        extraKey,
        fetchData,
        accesskey,
        firstload,
        validationToken,
        changeCurrent,
        localsettingcopy,
    ]);

    useEffect(() => {
        if (activetheme !== '') {
            const { type, presentationType } = props;
            if (presentationType.type !== 'pillow') {
                const accessToken: any = validationToken(type);
                accessToken.then(({ accesskey }: any) => {
                    getProductTemplate(baseUrl, clientID, extraKey, accesskey, {
                        presentation_type: type,
                        theme_id: activetheme,
                    })
                        .then(({ status, data }: any) => {
                            if (status === 200 && data.length > 0) {
                                setTemplate(data);
                            }
                        })
                        .catch((err: any) => {
                            const msg = catcchErrorHandling(err);
                            setError(msg);
                        });
                });
            }
        }
    }, [props, activetheme, baseUrl, clientID, extraKey, validationToken]);

    useEffect(() => {
        if (typeName === 'pillow' && tips === null) {
            const accessToken: any = validationToken(props.type);
            setLoading(1);
            accessToken.then(({ accesskey }: any) => {
                getAllTips(baseUrl, clientID, extraKey, accesskey)
                    .then(({ status, data = [] }: any) => {
                        if (status === 200) {
                            setTips(data);
                        }
                        setLoading(0);
                    })
                    .catch((err: any) => {
                        setLoading(0);
                        const msg = catcchErrorHandling(err);
                        setError(msg);
                    });
            });
        }
    }, [typeName, baseUrl, clientID, extraKey, accesskey, tips, props.type, validationToken]);

    // accesskey, props, fetchProductTemplate, fetchLoadDefault, fetchData
    async function fetchSlideVisibleChange(id: string, visible: any) {
        setLoading((old) => old + 1);
        const { accesskey }: any = await validationToken(props.type);
        postChangeSlideVisibility(baseUrl, id, presentationId, visible, clientID, extraKey, accesskey)
            .then(({ status }: any) => {
                if (status === 200) {
                    const updateSlide: any = sslides.map((aslide: any) => {
                        if (id === aslide._id) {
                            return { ...aslide, visible: visible };
                        }
                        return aslide;
                    });
                    setSlides(updateSlide);
                }
                setLoading((old) => old - 1);
            })
            .catch((err: any) => {
                setLoading((old) => old - 1);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchDeleteSlideRemove(id: string) {
        const { accesskey }: any = await validationToken(props.type);
        deleteRemoveSlide(baseUrl, id, presentationId, clientID, extraKey, accesskey)
            .then(({ status }: any) => {
                if (status === 200) {
                    let match = false;
                    const updateSlide: any = sslides
                        .sort((a: any, b: any) => a.order_no - b.order_no)
                        .map((aslide: any) => {
                            if (id === aslide._id) {
                                match = true;
                            }
                            if (match === true) {
                                return { ...aslide, order_no: aslide.order_no - 1 };
                            }
                            return aslide;
                        })
                        .filter((aslide: any) => {
                            if (id !== aslide._id) {
                                return aslide;
                            }
                            return null;
                        });
                    setSlides(updateSlide);
                    setrenderCount(renderCount + 1);
                }
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchAddSlide(anydata: any) {
        const theme = activetheme !== '' ? activetheme : '';
        const data: any = { ...anydata, theme };
        const { accesskey }: any = await validationToken(props.type);
        setLoading(1);
        postAddImageSlide(baseUrl, data, clientID, extraKey, accesskey, {
            sslides,
            setSlides,
            activeSlide,
            setActiveSlide,
            localMenuSlideEditValueUpdate,
            setCurrent,
        })
            .then(({ status, data }: any) => {
                if (status === 200) {
                    if (data !== undefined) {
                        thumbGenerator(false, 100);
                    }
                }
                setLoading(0);
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    const localMenuSlideEditValueUpdate = useCallback(
        (slide: any, element_id: any) => {
            slide.elements.map((as: any) => {
                if (as.element_id === element_id)
                    setLocalEdit({
                        _id: as._id,
                        element_id: 'background_image',
                        group: 'background',
                        slideType: current !== null && current.type !== '' ? current.type : 'image_slide',
                    });
                return null;
            });
        },
        [current]
    );

    async function fetchPresentationLogoUpdate(data: any) {
        const { accesskey }: any = await validationToken(props.type);
        postSavePresentationLogoInfo(baseUrl, data, presentationId, clientID, extraKey, accesskey)
            .then(({ status }: any) => {
                if (status === 200) {
                    setPresentationLogo({ ...presentationLogo, [data.type]: data.value });
                    // athumbGenerator(activeSlide, 1000);
                    setrenderCount(renderCount + 1);
                    // thumbGenerator(false, false, 100);
                }
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchCustomerLogoUpdate(data: any) {
        const { accesskey }: any = await validationToken(props.type);
        postSaveCustomerLogoInfo(baseUrl, data, presentationId, clientID, extraKey, accesskey)
            .then(({ status }: any) => {
                if (status === 200) {
                    setEditorLogo({ ...editorLogo, [data.type]: data.value });
                    thumbGenerator(false, 100);
                }
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchLookAndFeelUpdate(data: any) {
        const { accesskey }: any = await validationToken(props.type);
        postSaveLookAndFeel(baseUrl, data, presentationId, clientID, extraKey, accesskey, {
            look,
            setLook,
            setPresentationFont,
        })
            .then(({ status }: any) => {
                if (status === 200) {
                    setrenderCount(renderCount + 1);
                    // thumbGenerator(false, false, 100);
                }
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchSlidePropertyUpdate(data: any) {
        if (current === null) return;
        const retain = data.retain !== undefined ? data.retain : true;
        let newData: any = [];
        if (data.type === 'visible') {
            current.elements.map((e: any) => {
                if (e.group === data.group) {
                    newData.push({
                        id: e._id,
                        type: data.type,
                        value: data.value,
                        retain,
                    });
                    return { ...e, [data.type]: data.value };
                }
                return e;
            });
        } else if (data.group === 'background') {
            current.elements
                .filter((e: any) => e.group === data.group)
                .map((e: any) => {
                    if (data.type === 'fill') {
                        if (data.type in e) {
                            newData.push({
                                id: e._id,
                                type: data.type,
                                value: data.value,
                                retain,
                            });
                        } else {
                            newData.push({
                                id: e._id,
                                type: 'visible',
                                value: false,
                                retain,
                            });
                        }
                        return null;
                    } else {
                        if (data.type in e) {
                            if (e._id === data.id) {
                                newData.push({
                                    id: e._id,
                                    type: 'draw_value',
                                    value: data.value,
                                    retain,
                                });
                                newData.push({
                                    id: e._id,
                                    type: 'visible',
                                    value: true,
                                    retain,
                                });
                            }
                        }
                        return null;
                    }
                });
        } else if (data.group === 'presentation_logo' || data.group === 'end_customer_logo') {
            current.elements
                .filter((e: any) => e.group === data.group)
                .map((e: any) => {
                    if (data.type === 'fill') {
                        if (data.type in e) {
                            newData.push({
                                id: e._id,
                                type: data.type,
                                value: data.value,
                                retain,
                            });
                        } else {
                            newData.push({
                                id: e._id,
                                type: data.type,
                                value: data.value,
                                retain,
                            });
                        }
                    }
                    return null;
                });
        } else {
            newData = data;
        }

        const { accesskey }: any = await validationToken(props.type);
        postUpdateSlideProperty(baseUrl, newData, current._id, clientID, extraKey, accesskey, {
            current,
            setCurrent,
            sslides,
            setSlides,
        })
            .then(({ status }: any) => {
                if (status === 200) athumbGenerator(activeSlide, 500);
                setLoading(0);
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchBackgroundImages(data: any = {}, flag = true) {
        setLoading(1);
        const { accesskey }: any = await validationToken(props.type);
        getBackgroundImages(baseUrl, clientID, extraKey, accesskey, data, contactID, setBackgroundImageList)
            .then(({ status }: any) => {
                if (status === 200) setBackgroundview(flag);
                setLoading(0);
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function deleteBackgroundImage(data: any) {
        setLoading(1);
        const { accesskey }: any = await validationToken(props.type);
        deleteBackgroundImages(baseUrl, data, clientID, extraKey, accesskey, {
            setSlides,
            sslides,
            backgroundImageList,
            setBackgroundImageList,
        })
            .then(({ status }: any) => {
                if (status === 200) {
                    thumbGenerator(false, 100);
                }
            })
            .catch((err: any) => {
                const msg = catcchErrorHandling(err);
                setError(msg);
            })
            .finally(() => {
                setLoading(0);
            });
    }

    async function fetchUpdateTitle(data: any) {
        const { accesskey }: any = await validationToken(props.type);
        postUpdateTitle(baseUrl, data, clientID, extraKey, accesskey)
            .then(({ status }: any) => {
                if (status === 200) setPresentationTitle(data.title);
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchPostTemplateChange(data: any) {
        setLoading(1);
        const { accesskey }: any = await validationToken(props.type);
        postLoadProductTemplate(baseUrl, clientID, extraKey, accesskey, data, {
            setElement,
            sslides,
            setSlides,
            setCurrent,
        })
            .then(({ status }: any) => {
                // data, elements
                if (status === 200) {
                    setrenderCount(renderCount + 1);
                    // thumbGenerator(false, false, 100);
                }
                setLoading(0);
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchpostProductElementInfo(data: any) {
        const { accesskey }: any = await validationToken(props.type);
        postProductElementInfo(baseUrl, clientID, extraKey, accesskey, data, {
            element,
            setElement,
        })
            .then(({ status }: any) => {
                if (status === 200) thumbGenerator(false, 100);
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchpostProductDesignInfo(data: any) {
        const { accesskey }: any = await validationToken(props.type);
        postProductDesignInfo(baseUrl, clientID, extraKey, accesskey, data, {
            design,
            setDesign,
        })
            .then(({ status }: any) => {
                if (status === 200) thumbGenerator(false, 100);
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchEcqupdate(data: any) {
        const { accesskey }: any = await validationToken(props.type);
        postEcqupdate(baseUrl, clientID, extraKey, accesskey, data, {
            activeSlide,
            setCurrent,
            sslides,
            setSlides,
            ecqFormatting,
            setECQeditaData,
            setProductImages,
        })
            .then(({ status }: any) => {
                //resp, productReferenceData: PRD
                if (status === 200) {
                    athumbGenerator(activeSlide, 500);
                }
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchResetApi(data: any) {
        data.theme = activetheme;
        setLoading(1);
        const { accesskey }: any = await validationToken(props.type);
        postReset(baseUrl, clientID, extraKey, accesskey, data, {
            current,
            setCurrent,
            sslides,
            setSlides,
        })
            .then(({ status, data }: any) => {
                if (status >= 200 && status < 300) {
                    setLoading(0);
                    if (data.length > 0 && current !== undefined) {
                        athumbGenerator(activeSlide, 500);
                    }
                }
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }
    /* async function fetchThemes() {
        const { accesskey }: any = await validationToken(props.type);
        getThemes(baseUrl, clientID, extraKey, accesskey, type)
            .then(({ status, data }: any) => {
                if (status >= 200 && status < 300) {
                    setthemes(data);
                }
            })
            .catch((err: any) => {
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    } */

    async function fetchThemeUpdate(theme_id: any) {
        let layoutType: any = 0;
        template.map(({ _id, template_type }: any) => {
            if (_id === templateId) layoutType = template_type;
            return null;
        });
        const { accesskey }: any = await validationToken(props.type);
        setLoading(1);
        const data = {
            presentation_type: props.type,
            presentation_id: presentationId,
            theme_id,
            template_type: layoutType,
            image_slide_2_order_no: imageSlide2,
        };
        postSwitchTheme(baseUrl, clientID, extraKey, accesskey, data)
            .then(({ status, slides, ...resp }) => {
                if (status >= 200 && status < 300) {
                    const {
                        presentation_background,
                        presentation_display_name,
                        presentation_logo,
                        end_customer_logo,
                        look_and_feel,
                        _id,
                        product_template_id,
                        product_slide_elements = [],
                    } = resp;

                    if (resp.isPredefinedColor !== undefined) {
                        setIsPredefineColor(resp.isPredefineColor);
                    }

                    setElement(product_slide_elements);
                    setTemplateId(product_template_id);
                    setPresentationId(_id);
                    if (look_and_feel !== undefined && look_and_feel.font_name !== '') {
                        setPresentationFont(look_and_feel.font_name);
                    }
                    setPresentationBg(`#${presentation_background}`);
                    setPresentationTitle(presentation_display_name);
                    setPresentationLogo({
                        ...presentation_logo,
                        name: 'Show Debtor logo',
                    });
                    setEditorLogo({
                        ...end_customer_logo,
                        name: 'Show End customer logo',
                    });
                    setLook(look_and_feel);
                    setImageSlide2({ order_no: null, flag: false });
                    setSlides(slides);
                    thumbGenerator(false, 100);
                    if (activeSlide !== null) {
                        // changeCurrent(slides[activeSlide]);
                        localMenuSlideEditValueUpdate(slides[activeSlide], 'background_image');
                        setCurrent(slides[activeSlide]);
                    }
                    // setthumbload(true);
                    setLoading(0);
                }
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });

        getglobalSetting(baseUrl, clientID, extraKey, accesskey, {
            theme_id,
            presentation_type: props.type,
        })
            .then(({ status, data }) => {
                if (status >= 200 && status < 300) {
                    const { global_menu = undefined, fonts = [] } = data;
                    const setting: any = {};
                    if (global_menu !== undefined && global_menu.length > 0) {
                        global_menu.map((res: any) => {
                            const { name } = res;
                            setting[name] = res;
                            return null;
                        });
                    }
                    setfonts(fonts);
                    setlocalsettingcopy(setting);
                }
            })
            .catch((err: any) => {
                setLoading(0);
                const msg = catcchErrorHandling(err);
                setError(msg);
            });
    }

    async function fetchPresentationUpdate(data: any) {
        try {
            const { accesskey }: any = await validationToken(props.type);
            const { status }: any = await putPresentationUpdate(
                baseUrl,
                clientID,
                extraKey,
                accesskey,
                data,
                presentationId
            );
            if (status === 200) {
                setMargin(data.margin);
                return { status };
            }
        } catch (e) {
            const msg = catcchErrorHandling(e);
            setError(msg);
        }
    }

    useEffect(() => {
        let interval: any = null;
        if (isActive) {
            interval = window.setInterval(() => {
                if (activeSlide !== null) {
                    const no = activeSlide + 1;
                    if (no === sslides.length) {
                        setIsActive(false);
                        setActiveSlide(0);
                    } else {
                        setActiveSlide(activeSlide + 1);
                    }
                }
            }, timer);
        } else if (!isActive) {
            window.clearInterval(interval);
        }
        if (activeSlide !== null && sslides[activeSlide]) {
            const { name = '' }: any = sslides[activeSlide];
            if (name === 'Image Slide') localMenuSlideEditValueUpdate(sslides[activeSlide], 'background_image');
            setCurrent(sslides[activeSlide]);
        }
        return () => window.clearInterval(interval);
    }, [isActive, activeSlide, sslides, localMenuSlideEditValueUpdate]);

    useLayoutEffect(() => {
        props.fonts.map((ff: any) => {
            if (ff.font_family === presentationFont) {
                loadFonts({ ...ff, baseUrl });
            }
            return null;
        });
    }, [presentationFont, props.fonts, baseUrl]);

    useEffect(() => {
        if (activeSlide != null) {
            if (sslides[activeSlide]) {
                // changeCurrent(sslides[activeSlide], true);
                setCurrent(sslides[activeSlide]);
            } else {
                const active = activeSlide - 1;
                setActiveSlide(active);
                changeCurrent(sslides[active], true);
                // setCurrent(sslides[active]);
            }
        }
    }, [activeSlide, sslides, changeCurrent]);

    /*
     * SLID
     * ==============================
     * \__ Confirm defore deleting slide
     *    \__ Confirm alert message yes / no option
     *    \__ Only slide in presention can't prevent from deleting
     */
    const onDeleteItem = (id: any) => {
        if (sslides.length > 1) {
            if (!window.confirm('Are you sure, you want to delete this slide?')) return null;
            fetchDeleteSlideRemove(id);
        } else alert(`You can't delete all slide from presentation.`);
    };

    //* ADD SLIDE BELOW ACTIVE SLIDE
    const onAddImageSlide = (index: any, order_no: any) => {
        // console.log(activeSlide, index);
        // console.log(index, order_no);

        fetchAddSlide({
            presentation: presentationId,
            presentation_type: props.type,
            name: `Image Slide`,
            index,
            order_no: order_no + 1,
            // !activeSlide ? 2 : 2 + activeSlide,
        });
    };

    //* SLIDE VISIBLITY
    const onVisibleItem = (id: any, visible: boolean) => {
        fetchSlideVisibleChange(id, visible);
    };

    //* LOGO PRESENTATION
    const onPresentationLogoChange = (data: any) => {
        fetchPresentationLogoUpdate(data);
    };

    //* LOGO CUSTOMER
    const onCustomerLogoChange = (data: any) => {
        fetchCustomerLogoUpdate(data);
    };

    //* LOOK AND FEEL
    const onLookAndFeelChange = (look_and_feel: any) => {
        fetchLookAndFeelUpdate(look_and_feel);
    };

    //* SLIDE UPDATE
    const onSlideUpdate = (slide_update: any) => {
        fetchSlidePropertyUpdate(slide_update);
    };

    //* SLIDE UPDATE
    const onBackgroundChange = () => {
        fetchBackgroundImages('/');
    };

    //* PRODUCT PRODUCT IMAGE CHANGE
    const onProductImageChange = (obj: any) => {
        setLocalEdit(obj);
        setBackgroundview(true);
        let displayImage: any = '';
        productImages.map((pi: any) => {
            if (pi.product_id === obj.product_id) {
                if (obj.group === 'decorator_product_image' && pi.artwork_design !== '') {
                    displayImage = [...pi.images, pi.artwork_design];
                } else {
                    displayImage = pi.images;
                }
            }
            return null;
        });
        const pimg: any = displayImage.map((img: any) => {
            return { name: img, thumb: img, url: img };
        });
        // setProductImages(pimg);
        setBackgroundImageList(pimg);
    };

    const onTitleChange = (title: string) => {
        fetchUpdateTitle({ presentation: presentationId, title });
    };

    const onTemplateChange = (product_template: any, slide_id: any = null) => {
        fetchPostTemplateChange({
            presentation: presentationId,
            product_template,
            presentation_type: props.type,
            slide: slide_id,
        });
    };

    const onElementChangge = (element_change: any) => {
        const obj = {
            pillow: true,
            element_change,
            presentation: presentationId,
            id: element_change._id,
        };
        if (element_change.element_id === 'colors') {
            fetchpostProductElementInfo({ ...obj, value: element_change.value });
        } else if (element_change.element_id === 'style' || element_change.element_id === 'product_highlight') {
            if (element_change.name === 'status') {
                fetchpostProductElementInfo({ ...obj, value: element_change.status });
            } else {
                fetchpostProductElementInfo({ ...obj, value: element_change.value });
            }
        } else {
            const value = element_change.value === false;
            fetchpostProductElementInfo({
                presentation: presentationId,
                id: element_change._id,
                value,
            });
        }
    };

    const onDesignChangge = ({ _id, value }: any) => {
        fetchpostProductDesignInfo({
            presentation: presentationId,
            id: _id,
            value: value,
        });
    };

    const onEcqUpdate = (obj: any) => {
        const presentation = presentationId;
        const slide = current._id !== undefined ? current._id : '';
        const presentation_type = props.type;

        // console.log(templateId, template)
        let productTemplateName = '';
        template.map((tmp: any) => {
            if (tmp._id === templateId) {
                productTemplateName = tmp.name;
            }
            return null;
        });
        if (obj.reset_to_default !== undefined) {
            fetchEcqupdate({
                presentation,
                slide,
                presentation_type,
                productTemplateName,
                ...obj,
            });
            return null;
        }
        fetchEcqupdate({
            presentation,
            slide,
            presentation_type,
            productTemplateName,
            data: { ...obj },
        });
    };

    const onResetApi = (obj: any) => {
        fetchResetApi({
            presentation: presentationId,
            presentation_type: props.type,
            ...obj,
        });
    };

    /* const onSave = () => {
        if (props.clientID) {
            const obj = {
                presentation_background: 'ffff0f',
                slides: sslides,
            };
            const { baseUrl, clientID, extraKey, accesskey } = props;
            saveDefaultTheme(baseUrl, clientID, extraKey, accesskey, obj).then((res: any) => {
                console.log(res);
            });
        }
    }; */

    const settingViewUI = () => {
        if (!settingView) fetchThemes();
        setSettingView(!settingView);
    };

    const colorPickerUI = (obj: any) => {
        setBgActive(obj);
        setColorpickview(true);
    };

    const colorPickerCompUI = () => {
        setColorpickview(false);
    };

    const EditView = () => {
        if (current._id !== undefined) {
            if ('product_id' in current) {
                // ** Since older slides doesnot have product_no so using product_id for older slides
                const key = current.product_no !== undefined ? 'product_no' : 'product_id';
                const productSLide = ecqEditData.filter((c: any) => c[`${key}`] === current[`${key}`])[0];
                const { data, more_total, price_format, prodict_id, total } = productSLide;

                const defaultValue = ecqDefaultData.filter((c: any) => c[`${key}`] === current[`${key}`])[0];
                return (
                    <Editable
                        ecqdata={data}
                        defaultdata={defaultValue['data']}
                        defaultmore={defaultValue['more_total']}
                        setView={setECQView}
                        currency={price_format} // currency
                        product_id={prodict_id}
                        product_no={current['product_no']}
                        more={more_total}
                        total={total}
                        onUpdate={onEcqUpdate}
                    />
                );
            }
        }
        return null;
    };

    const localmenuCloseForothereComponent = () => {
        const element = document.getElementById('localani');
        if (element) {
            // element?.classList.remove("active");
            setTimeout(() => setLocalmenu(false), 600);
        }
    };

    //TODO : improve this PDF start
    // const testobj = {
    //     presentationFont,
    //     presentationLogo,
    //     editorLogo,
    //     look,
    //     element,
    // };
    useEffect(() => {
        if (sslides.length > 0) {
            thumbGenerator(thumbload, 200);
            setthumbload(true);
        }
    }, [sslides, thumbload]);
    // testobj,

    useEffect(() => {
        const selection: any = document.querySelector('.main.board');
        const pdfTarget: any = selection.cloneNode(true);
        if (pdfCreating) {
            const pdfReq: any = [];
            setLoading(1);
            setTimeout(() => {
                const hoverButton: any = pdfTarget.querySelectorAll('.editbackgroundimage');
                /* let threeD: any = pdfTarget.querySelectorAll(".threeDmodelwrap");
                if (threeD.length) {
                    for (let zz = 0; zz < threeD.length; zz++) {
                        if (threeD[zz]) {
                            threeD[zz]?.parentNode?.removeChild(threeD[zz]);
                        }
                    }
                }
                */
                if (hoverButton.length > 0) {
                    for (let zz = 0; zz < hoverButton.length; zz++) {
                        if (hoverButton[zz] !== undefined) {
                            hoverButton[zz]?.parentNode?.removeChild(hoverButton[zz]);
                        }
                    }
                }
                const gene: any = pdfTarget.querySelectorAll('.main.board > div > div');
                const geneArray = Array.prototype.slice.call(gene);

                if (geneArray !== undefined && geneArray.length > 0) {
                    geneArray.forEach((g: any, index: any) => {
                        const dataTitle = g.firstChild.getAttribute('data-title');
                        const type = dataTitle !== '' ? dataTitle : 'No title fetched';
                        const obj = {
                            order: index + 1,
                            type,
                            html: g?.innerHTML, // && ((g).innerHTML).replace(/"/g, "\\")
                        };
                        // console.log(g.innerHTML);
                        // document.getElementById('testpdf')?.append(g);

                        if (sslides[index]) {
                            const { visible = true } = sslides[index];
                            if (visible) pdfReq.push(obj);
                        }
                        return null;
                    });
                }
                // setLoading(1);
                // return null;
                // TODO: pdf generating API request goes here
                // onDownloadPDF(pdfReq);
                if (pdfReq.length > 0) {
                    const accessToken: any = validationToken(props.type);
                    accessToken.then(({ accesskey }: any) => {
                        postPDF(baseUrl, clientID, extraKey, accesskey, {
                            presentation: presentationId,
                            file: pdfFile !== '' ? pdfFile : null,
                            contents: pdfReq,
                        })
                            .then(() => {
                                setLoading(0);
                            })
                            .catch((err: any) => {
                                setLoading(0);
                                const msg = catcchErrorHandling(err);
                                setError(msg);
                            });
                    });
                } else {
                    setLoading(0);
                    alert(pdferrormsg);
                }
                setPdfCreating(false);
                // pdfTarget?.remove();
                // const rm = document.getElementById('testID');
                // rm?.parentNode?.removeChild(rm);
            }, 300);
        }
    }, [
        pdfCreating,
        sslides,
        editorLogo,
        element,
        look,
        presentationBg,
        presentationFont,
        presentationLogo,
        baseUrl,
        clientID,
        extraKey,
        accesskey,
        presentationId,
        props.type,
        validationToken,
        pdfFile,
    ]);

    //TODO : improve this PDF END
    const athumbGenerator = (active: any, time = 500) => {
        setTimeout(function () {
            const target: any = document.querySelector(`#slide-${active} .ground`);
            const subt: any = document.getElementById(`strip_${active + 1}`);
            const removeDiv: any = document.querySelector(`#stripitem_${active + 1} .testThumb`);
            const thumbWrap = document.createElement('div');
            if (target !== undefined) {
                const cl = target.cloneNode(true);
                thumbWrap.classList.add('testThumb');
                thumbWrap.appendChild(cl);
                removeDiv.parentNode.removeChild(removeDiv);
                if (subt !== undefined) subt.parentNode.insertBefore(thumbWrap, subt.nextSibling);
            }
        }, time);
    };

    const thumbGenerator = (flag = false, time = 200) => {
        if (!flag) {
            setTimeout(function () {
                const w = '146px';
                const h = '81px';
                const del: any = document.querySelectorAll('.testThumb'); //.forEach(e => e.remove());
                for (let ll = 0; ll < del.length; ll++) {
                    if (del[ll] !== undefined) {
                        // del[ll].remove();
                        del[ll].parentNode.removeChild(del[ll]);
                    }
                }

                const tar: any = document.querySelectorAll('.ground');
                for (let kk = 0; kk <= tar.length; kk++) {
                    if (tar[kk] !== undefined) {
                        const thumbWrap = document.createElement('div');
                        const cl = tar[kk].cloneNode(true);
                        thumbWrap.classList.add('testThumb');
                        thumbWrap.appendChild(cl);

                        const dd2 = document.createElement('div');
                        dd2.style.width = w;
                        dd2.style.height = h;
                        dd2.classList.add('one');
                        thumbWrap.appendChild(dd2);

                        const subt: any = document.getElementById(`strip_${kk + 1}`);
                        if (subt !== undefined) subt.parentNode.insertBefore(thumbWrap, subt.nextSibling);
                    }
                }
            }, time);
        }
    };
    const emailCopyLink = () => {
        const pdfReq: any = [];
        setLoading(1);
        const selection: any = document.querySelector('.main.board');
        const pdfTarget: any = selection.cloneNode(true);
        setTimeout(() => {
            const hoverButton: any = pdfTarget.querySelectorAll('.editbackgroundimage');
            /* let threeD: any = pdfTarget.querySelectorAll(".threeDmodelwrap");
            if (threeD.length) {
                for (let zz = 0; zz < threeD.length; zz++) {
                    if (threeD[zz]) {
                        threeD[zz]?.parentNode?.removeChild(threeD[zz]);
                    }
                }
            } */

            if (hoverButton.length > 0) {
                for (let zz = 0; zz < hoverButton.length; zz++) {
                    if (hoverButton[zz] !== undefined) {
                        hoverButton[zz]?.parentNode?.removeChild(hoverButton[zz]);
                    }
                }
            }
            const gene: any = pdfTarget.querySelectorAll('.main.board > div > div');
            const geneArray = Array.prototype.slice.call(gene);
            console.log(gene, typeof geneArray);
            if (geneArray !== undefined && geneArray.length > 0) {
                geneArray.forEach((g: any, index: any) => {
                    const dataTitle = g.firstChild.getAttribute('data-title');
                    const type = dataTitle !== '' ? dataTitle : 'No title fetched';
                    const obj = {
                        order: index + 1,
                        type,
                        html: g?.innerHTML, // && ((g).innerHTML).replace(/"/g, "\\")
                    };
                    // console.log(g.innerHTML);
                    // document.getElementById("testpdf")?.append(g);
                    if (sslides[index]) {
                        const { visible = true } = sslides[index];
                        if (visible) pdfReq.push(obj);
                    }
                    return null;
                });
            }
            if (pdfReq.length > 0) {
                const accessToken: any = validationToken(props.type);
                accessToken.then(({ accesskey }: any) => {
                    postPDF(
                        baseUrl,
                        clientID,
                        extraKey,
                        accesskey,
                        {
                            presentation: presentationId,
                            contents: pdfReq,
                            type: 'link',
                        },
                        false
                    )
                        .then(({ link, pdf }: any) => {
                            setpdfcopylink(link);
                            setPdfFile(pdf);
                            setLoading(0);
                            setemailShareFlag(true);
                        })
                        .catch((err: any) => {
                            setLoading(0);
                            const msg = catcchErrorHandling(err);
                            setError(msg);
                        });
                });
            } else {
                setLoading(0);
                alert(pdferrormsg);
            }
        }, 300);
    };

    const onImageUpload = (data: any, obj: any) => {
        const accessToken: any = validationToken(props.type);
        setLoading(1);
        accessToken.then(({ accesskey }: any) => {
            postImageUpload(baseUrl, clientID, extraKey, accesskey, { images: data }, contactID)
                .then(({ status, background }: any) => {
                    if (status === 200) {
                        if (background !== undefined && background.length > 0) {
                            const backgroundObj = { ...obj, value: background[0].url };
                            onSlideUpdate(backgroundObj);
                        }
                    }
                    setLoading(0);
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    };

    const onToggleColor = (data: any) => {
        const accessToken: any = validationToken(props.type);
        setLoading(1);

        accessToken.then(({ accesskey }: any) => {
            putToggleColor({
                baseUrl,
                clientID,
                extraKey,
                accesskey,
                data: { ...data.payload },
                presentationID: presentationId,
            })
                .then(({ status }: any) => {
                    if (status === 200) {
                        if (data.col !== undefined) {
                            // setActiveColor(data.col);
                            setActiveColor({
                                _id: data.col._id,
                                name: data.col.name,
                                main: data.col.main,
                                accent1: data.col.accent1,
                                accent2: data.col.accent2,
                                // status: true,
                            });
                        }
                        setIsPredefineColor(data.payload.isPredefinedColor);
                        setIsCustomColor(data.payload.isCustomColor);
                        setthumbload(!thumbload);
                    }
                    setLoading(0);
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                })
                .finally(() => {
                    if (data.col !== undefined && data.col?.fontColor !== undefined)
                        fetchLookAndFeelUpdate({
                            type: 'font_color',
                            value: data.col.fontColor,
                        });
                });
        });
    };
    const onSaveColor = (data: any) => {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            saveColor(
                baseUrl,
                {
                    ...data,
                    presentationId: presentationId,
                    name: 'test',
                    order: 3,
                    fontColor: '',
                },
                clientID,
                extraKey,
                accesskey
            )
                .then(({ status, data }: any) => {
                    if (status === 200) {
                        setCustomColor([...customColor, data]);
                    }
                })
                .catch((error: any) => {
                    const {
                        response = {
                            data: {
                                message: '',
                            },
                        },
                    } = error;
                    const message = response.data.message;
                    setMessage((prev: any) => ({
                        ...prev,
                        display: true,
                        message,
                        fn: () => null,
                    }));
                });
        });
    };
    const onDeleteColor = (colorId: any) => {
        const accessToken: any = validationToken(props.type);
        accessToken.then(({ accesskey }: any) => {
            deleteColor(baseUrl, presentationId, colorId._id, clientID, extraKey, accesskey).then(
                ({ status, colorId, currentPresentation, product_slide_elements }: any) => {
                    if (status === 200) {
                        if (
                            currentPresentation.isPredefinedColor === false &&
                            currentPresentation.isCustomColor === false
                        ) {
                            setIsPredefineColor(false);
                            setIsCustomColor(false);
                        }
                        setElement(product_slide_elements);
                        // athumbGenerator(activeSlide, 500);
                        thumbGenerator(false, 100);
                        setCustomColor((prev: any) => prev.filter((p: any) => p._id !== colorId));
                    }
                }
            );
        });
    };

    return (
        <React.Fragment>
            <languageContext.Provider value={language}>
                <MessageContext.Provider value={{ ...message, setMessage }}>
                    <div className="flex flex-column cc-container">
                        <TitleBar
                            display={display}
                            setDisplayOverview={(flag: any) => {
                                const accessToken: any = validationToken(props.type);
                                accessToken.then(() => setDisplayOverview(flag));
                            }}
                            title={presentationTitle}
                            setTitle={onTitleChange}
                            onEmailShareflag={emailCopyLink}
                        />
                        <div className="flex flex01">
                            <MenuProvider>
                                <settingContext.Provider
                                    value={{ _: 'settingContext', setting: localsettingcopy, typeName }}
                                >
                                    <lookandFeelContext.Provider
                                        value={{
                                            _: 'lookandFeelContext',
                                            templateColor: props.templateColor,
                                            brandColor: props.brandColor,
                                            fonts,
                                            lookFeel: look,
                                            template,
                                            onTemplateChange,
                                            templateId,
                                            setTemplateId,
                                            setElement,
                                            element,
                                            design,
                                            setDesign,
                                            onElementChangge,
                                            onDesignChangge,
                                            baseUrl,
                                            colorScheme,
                                            setColorScheme,
                                            activeColor,
                                            isPredefineColor,
                                            isCustomColor,
                                            customColor,
                                            onToggleColor,
                                            setIsPredefineColor,
                                            setIsCustomColor,
                                            onSaveColor,
                                            setCustomColor,
                                            onDeleteColor,
                                        }}
                                    >
                                        <lookContext.Provider value={{ presentationLogo, editorLogo }}>
                                            <ThemeContext.Provider
                                                value={{
                                                    themes,
                                                    activetheme,
                                                    setactivetheme,
                                                    localsettingcopy,
                                                    fetchThemeUpdate,
                                                    fetchPresentationUpdate,
                                                    margin,
                                                }}
                                            >
                                                <SideBar
                                                    settingViewUI={settingViewUI}
                                                    colorpickview={colorPickerUI}
                                                    onPresentationLogoChange={onPresentationLogoChange}
                                                    onCustomerLogoChange={onCustomerLogoChange}
                                                    onLookAndFeelChange={onLookAndFeelChange}
                                                    setDim={setDim}
                                                />
                                            </ThemeContext.Provider>
                                        </lookContext.Provider>
                                    </lookandFeelContext.Provider>
                                </settingContext.Provider>
                                <main
                                    className="flex flex-column justify-space-between flex01"
                                    style={{
                                        overflow: 'hidden',
                                        background: '#E2E2E2',
                                    }}
                                >
                                    <boardContext.Provider
                                        value={{
                                            presentationFont,
                                            presentationLogo,
                                            editorLogo,
                                            look,
                                            element,
                                            design,
                                            margin,
                                            localEdit,
                                            tips,
                                            colorpickview: colorPickerUI,
                                            currentSlide: activeSlide !== null ? sslides[activeSlide] : null,
                                        }}
                                    >
                                        {/* SLIDE ACTION */}
                                        <ActionBar
                                            current={current}
                                            elements={current?.elements}
                                            localEdit={localEdit}
                                            colorpickview={colorPickerUI}
                                            onBackgroundChange={setBackgroundUpload}
                                            onResetApi={onResetApi}
                                            onSlideUpdate={onSlideUpdate}
                                            layout={template}
                                            templateId={templateId}
                                            onTemplateChange={onTemplateChange}
                                            setTemplateId={setTemplateId}
                                            typeName={typeName}
                                        />
                                        <div
                                            ref={mainRef}
                                            className="flex justify-center align-center main flex01 overflow-auto board"
                                        >
                                            <div
                                                className="slide-wrapper absolute"
                                                ref={slideRef}
                                                style={{ transform: `scale(${dim.scale})` }}
                                            >
                                                <LayoutContext.Provider
                                                    value={{
                                                        templateId,
                                                        template,
                                                        activeColor,
                                                        isPredefineColor,
                                                        isCustomColor,
                                                    }}
                                                >
                                                    {sslides.length > 0 &&
                                                        sslides.map((ss: any, index: number) => {
                                                            const styl: any =
                                                                index === activeSlide ? '' : { display: 'none' };
                                                            // WIP PILLOW CHANGES
                                                            // || ss.type === "product_slide"
                                                            if (typeName === 'pillow') {
                                                                if (ss.type === 'product_slide') {
                                                                    return (
                                                                        <div
                                                                            key={ss._id}
                                                                            data-slides={index}
                                                                            id={`slide-${index}`}
                                                                            style={{ ...styl }}
                                                                        >
                                                                            <div className="ground">
                                                                                <div
                                                                                    className={`cc slide-${ss._id}`}
                                                                                    data-title={ss.name}
                                                                                >
                                                                                    <PillowSlide
                                                                                        {...ss}
                                                                                        id={`slide-${ss._id}`}
                                                                                        name={ss.name}
                                                                                        elements={ss.elements}
                                                                                        onSlideUpdate={onSlideUpdate}
                                                                                        setBackgroundview={
                                                                                            setBackgroundview
                                                                                        }
                                                                                        setBackgroundImageList={
                                                                                            setBackgroundImageList
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                if (ss.type === 'contact_slide') {
                                                                    return (
                                                                        <div
                                                                            key={ss._id}
                                                                            data-slides={index}
                                                                            id={`slide-${index}`}
                                                                            style={{ ...styl }}
                                                                        >
                                                                            <div className="ground">
                                                                                <div
                                                                                    className={`cc slide-${ss._id}`}
                                                                                    data-title={ss.name}
                                                                                >
                                                                                    <ContactSlide
                                                                                        {...ss}
                                                                                        id={`slide-${ss._id}`}
                                                                                        name={ss.name}
                                                                                        elements={ss.elements}
                                                                                        presentationLogo={
                                                                                            presentationLogo
                                                                                        }
                                                                                        editorLogo={editorLogo}
                                                                                        onSlideUpdate={onSlideUpdate}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div
                                                                    key={ss._id}
                                                                    data-slides={index}
                                                                    id={`slide-${index}`}
                                                                    style={{ ...styl }}
                                                                >
                                                                    <Board
                                                                        {...ss}
                                                                        id={`slide-${ss._id}`}
                                                                        name={ss.name}
                                                                        elements={ss.elements}
                                                                        presentationLogo={presentationLogo}
                                                                        editorLogo={editorLogo}
                                                                        onSlideUpdate={onSlideUpdate}
                                                                        setECQView={setECQView}
                                                                        onProductImageChange={onProductImageChange}
                                                                        localmenuView={(mode: any) => {
                                                                            setLocalEdit(mode);
                                                                            // setLocalmenu(true);
                                                                        }}
                                                                        currentid={current?._id}
                                                                        currenttype={current?.type}
                                                                        productTempType={current?.product_template_type}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                </LayoutContext.Provider>
                                            </div>
                                        </div>
                                    </boardContext.Provider>
                                    {/* SLIDE NAVIGATION */}
                                    <Slides
                                        sliders={sslides}
                                        index={activeSlide}
                                        baseUrl={baseUrl}
                                        clientID={clientID}
                                        extraKey={extraKey}
                                        acckey={accesskey}
                                        presentation={presentationId}
                                        thumbAction={() => validationToken(props.type)}
                                        setLoading={setLoading}
                                        setError={setError}
                                        setCurrent={setCurrent}
                                        change={(target: any) => {
                                            setActiveSlide(() => target);
                                            changeCurrent(sslides[target]);

                                            /* console.log(target);
                                            console.log(sslides[target]);
                                            console.log('-- -- -- * -- -- --'); */
                                            /* if (target !== activeSlide) {
                                                setLocalEdit({});
                                            } */
                                            // setLocalmenu(false);
                                        }}
                                        onDeleteItem={onDeleteItem}
                                        onVisibleItem={onVisibleItem}
                                        onAddImageSlide={onAddImageSlide}
                                        renderCount={renderCount}
                                        setSlides={setSlides}
                                        thumbGenerator={thumbGenerator}
                                        setImageSlide2={setImageSlide2}
                                        activeIndex={activeSlide}
                                        setDim={setDim}
                                    />
                                </main>
                            </MenuProvider>
                        </div>
                    </div>

                    {colorpickview && (
                        <ColorPicker
                            data={bgActive}
                            localEdit={{}}
                            view={colorPickerCompUI}
                            onPresentationLogoChange={onPresentationLogoChange}
                            onCustomerLogoChange={onCustomerLogoChange}
                            onLookAndFeelChange={onLookAndFeelChange}
                            onSlideUpdate={onSlideUpdate}
                            elements={current !== undefined && current.elements}
                            localmenuClose={() => localmenuCloseForothereComponent()}
                            logoColor={logoColorList}
                            settingColor={{
                                element,
                                setElement,
                                onElementChangge,
                            }}
                        />
                    )}
                    {backgroundView && (
                        <BackgroundImage
                            localEdit={localEdit}
                            view={setBackgroundview}
                            backgroundImageList={backgroundImageList}
                            setBackgroundImageList={setBackgroundImageList}
                            deleteBackgroundImage={deleteBackgroundImage}
                            onSlideUpdate={onSlideUpdate}
                            elements={current !== undefined && current?.elements}
                            localmenuClose={() => localmenuCloseForothereComponent()}
                            slideType={current?.type}
                            breadcrumb={{
                                list: bread,
                                setbread,
                                fetch: fetchBackgroundImages,
                            }}
                            setbackgroundSelectOption={setbackgroundSelectOption}
                            setBackgroundUpload={setBackgroundUpload}
                        />
                    )}
                    {backgroundUpload && (
                        <BackgroundSelect
                            localEdit={localEdit}
                            view={setBackgroundUpload}
                            onBackgroundSelect={onBackgroundChange}
                            backgroundImageList={backgroundImageList.length}
                            onSlideUpdate={onSlideUpdate}
                            localmenuClose={() => localmenuCloseForothereComponent()}
                            imageUpload={onImageUpload}
                            propChoice={backgroundSelectOption}
                            setbackgroundSelectOption={setbackgroundSelectOption}
                        />
                    )}

                    <Loader isLoader={isLoading} />

                    {ecqView && <EditEcqContext.Provider value={{ ...look }}>{EditView()}</EditEcqContext.Provider>}
                    {emailshareflag && (
                        <EmailShare
                            pid={presentationId}
                            onPdfFunction={setPdfCreating}
                            closeEmailShare={() => setemailShareFlag(false)}
                            pdfurl={pdfcopylink}
                        />
                    )}
                    {error !== '' && false && <ErrorBox error={error} close={() => setError('')} />}
                    <Message
                        {...message}
                        color={() => {
                            setMessage({
                                display: false,
                                message: '',
                                fn: () => null,
                            });
                        }}
                    />
                </MessageContext.Provider>
            </languageContext.Provider>
        </React.Fragment>
    );
};
export default Presentation;
