import React, { useState, useContext, useEffect, useRef } from 'react';
import { editableCloseIcon, downloadArrowIcon, tickMark } from '../../helpers/IconList';
import languageContext from '../../context/languageContext';
import { QRCodeCanvas } from 'qrcode.react';

declare global {
    interface Window {
        dataLayer: any;
    }
}
interface EmailSharePropsType {
    closeEmailShare?: any;
    onShare?: any;
    pdfurl?: any;
    onPdfFunction?: any;
    onPdfFlag?: any;
    pid: any;
}

function EmailShare({ onPdfFlag, onPdfFunction, closeEmailShare, pdfurl, pid }: EmailSharePropsType) {
    const [url, seturl] = useState('');
    const [copyed, setcopied] = useState(false);
    const [displaytime] = useState(3000);
    const fontStyle = {};
    const buttonref = useRef<null | HTMLElement>(null);

    // Translation text destructuring
    const { share = 'Share', copy, copied = 'Copied', download = '' }: any = useContext(languageContext);
    useEffect(() => {
        seturl(pdfurl);
        buttonref.current!.focus();
    }, [pdfurl]);

    /**
     * PDF generated URL copy to clipboard
     * @bried this will copy PDF URL to clipboard
     * */
    function copyfunction() {
        navigator.clipboard.writeText(url);
        googleTrackDataLayer('link');
        setcopied(true);
        setTimeout(() => {
            setcopied(false);
        }, displaytime);
    }

    /**
     * Dowlaod QR Code as Image
     * @brief conver QR code generated from QR packag
     * and save it in local machine
     * */
    function downloadQRImage() {
        const canvasEle: any = document.getElementById('qrqr') || null;

        if (canvasEle !== null) {
            var url = canvasEle?.toDataURL('image/png') || null;
            var link = document.createElement('a');
            link.download = pid ? pid + '.png' : 'filename.png';
            link.href = url;
            googleTrackDataLayer('qr-code');
            link.click();
            link.remove();
        }
    }

    function downloadPDF() {
        googleTrackDataLayer('pdf');
        onPdfFunction(!onPdfFlag);
    }

    function googleTrackDataLayer(label: string) {
        if (window?.dataLayer) {
            window.dataLayer.push({
                event: 'catalogue_creator-presentation.download',
                send_to_ga: true,
                cat: 'catalogue_creator_presentation',
                action: 'download',
                label,
            });
        } else {
            console.log('CC: Something went wrong.');
        }
    }

    /**
     * Download PDF download with API call with existing PDF url
     */
    // function downloadPDF() {
    //     if (url) {
    //         // Create a new link
    //         const anchor = document.createElement('a');
    //         anchor.setAttribute("target", '_blank');
    //         anchor.href = url;
    //         anchor.download = url;
    //         // Append to the DOM
    //         document.body.appendChild(anchor);
    //         // Trigger `click` event
    //         anchor.click();
    //         // Remove element from DOM
    //         document.body.removeChild(anchor);
    //     }
    // }

    return (
        <div className="editBoxWrap">
            <div className="editBox editBox-width">
                <div className="backgroundBox__title title-size">
                    {/* <h4 style={fontStyle}>{email_and_share}</h4> */}
                    <h4 style={fontStyle} className="share-header">
                        {share}
                    </h4>
                    <button onClick={closeEmailShare}>
                        <img src={editableCloseIcon} alt="close" />
                    </button>
                </div>
                <div className="downloadBox">
                    <div className="downloadBox-item qr-scan">
                        <div className="downloadBox-icon">
                            <QRCodeCanvas id="qrqr" height="100%" width="100%" fgColor="#05457C" value={url} />
                        </div>
                        <span onClick={() => downloadQRImage()} style={{ cursor: 'pointer' }}>
                            <img alt="arrow" src={downloadArrowIcon} className="mr10" />
                            {download}
                        </span>
                    </div>
                    <hr className="downloadBox-share"></hr>
                    <div className="downloadBox-item pdf-download">
                        <div className="downloadBox-icon">
                            <svg
                                id="Icon_-_File-PDF"
                                data-name="Icon - File-PDF"
                                width="40.875"
                                height="42"
                                viewBox="0 0 40.875 42"
                            >
                                <path
                                    id="Path_2853"
                                    data-name="Path 2853"
                                    d="M33.938,44.24H3.856a1.615,1.615,0,0,1-1.615-1.615V3.856A1.615,1.615,0,0,1,3.856,2.241H22.35a1.615,1.615,0,0,1,0,3.231H5.472V41.01H32.322V15.339a1.615,1.615,0,1,1,3.231,0V42.625A1.615,1.615,0,0,1,33.938,44.24Z"
                                    transform="translate(-2.241 -2.24)"
                                    fill="#000"
                                ></path>
                                <path
                                    id="Path_2854"
                                    data-name="Path 2854"
                                    d="M325.3,17.352H314.112a2.016,2.016,0,0,1-2.016-2.012V4.253a2.016,2.016,0,0,1,2.016-2.012,1.615,1.615,0,0,1,1.137.468l11.588,11.483a1.615,1.615,0,0,1,.478,1.147A2.016,2.016,0,0,1,325.3,17.352Zm-9.972-3.231h6.848l-6.848-6.786Z"
                                    transform="translate(-294.003 -2.241)"
                                    fill="#000"
                                ></path>
                                <rect
                                    id="Rectangle_698"
                                    data-name="Rectangle 698"
                                    width="24.5"
                                    height="11.667"
                                    transform="translate(16.375 22.257)"
                                    fill="red"
                                ></rect>
                                <path
                                    id="Path_2853-2"
                                    data-name="Path 2853"
                                    d="M.5.933H2.307V-.933h.905c1.615,0,2.8-.812,2.8-2.361v-.019C6.012-4.751,4.948-5.6,3.305-5.6H.5ZM2.307-2.352V-4.079h.859c.644,0,1.045.289,1.045.849v.019c0,.513-.4.859-1.036.859ZM6.833.933H9.325c2.343,0,3.715-1.4,3.715-3.285v-.019c0-1.885-1.353-3.229-3.677-3.229H6.833ZM9.381-4a1.616,1.616,0,0,1,1.811,1.661v.019A1.605,1.605,0,0,1,9.381-.672H8.644V-4ZM14.039.933h1.811V-1.4h3.089V-2.9H15.849V-4.013h3.416V-5.6H14.039Z"
                                    transform="translate(18.791 30.657)"
                                    fill="#fff"
                                ></path>
                            </svg>
                        </div>
                        <span onClick={downloadPDF} style={{ cursor: 'pointer' }}>
                            <img alt="arrow" src={downloadArrowIcon} className="mr10" />
                            {download}
                        </span>
                    </div>
                </div>
                <div className="backgroundBox__body emailshare emailshare-size">
                    <div>
                        <div className="mailsharevalue mailsharevalue-size">
                            <input type="text" className="mailshare-font" value={url} onChange={() => {}} readOnly />
                            <button
                                onClick={copyfunction}
                                ref={buttonref as any}
                                className="mailshare-link"
                                style={{ color: copyed ? '#2BD313' : '#00457C' }}
                            >
                                {copyed ? (
                                    <img alt="copied-icon" src={tickMark} className="mr10" />
                                ) : (
                                    <img alt="arrow" src={downloadArrowIcon} className="mr5" />
                                )}
                                <span>{copyed ? copied : copy}</span>
                            </button>
                        </div>
                    </div>
                    {/* {copied && <p>{share_link_copied_to_clipboard}.</p>} */}
                </div>
            </div>
        </div>
    );
}
export default EmailShare;
