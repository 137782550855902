import React, { useContext } from 'react';
import languageContext from '../../../context/languageContext';

const MessageDialog = ({ dialogAction, dialogMsg, btnCancel = true }: any) => {
    const language = useContext<any>(languageContext);

    return (
        <aside className="pfc-dialog pfc-dialog_medium cc-dialog">
            <div className="modal-inner-wrap">
                <div id="modal-content-15" className="modal-content" data-role="content">
                    <div id="my-modal" data-role="modal" className="modal-content-wrap">
                        <div data-role="header">
                            <button
                                className="action-close"
                                data-role="closeBtn"
                                type="button"
                                onClick={() => dialogAction(false)}
                            >
                                <span>Close</span>
                            </button>
                        </div>
                        <div data-role="content">
                            <h2>&nbsp;</h2>
                            <div id="modal-content" className="modal-content-inner">
                                <p>{dialogMsg}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="modal-footer">
                    {btnCancel === true && (
                        <button
                            className="action tertiary btn-icon"
                            type="button"
                            data-role="action"
                            onClick={() => dialogAction(false)}
                        >
                            <i className=" icon pfc-arrow-right"></i>
                            <span>{language?.btn_no}</span>
                        </button>
                    )}
                    <button
                        className="action primary "
                        type="button"
                        data-role="action"
                        onClick={() => dialogAction(true)}
                    >
                        <span>{language?.btn_yes}</span>
                    </button>
                </footer>
            </div>
        </aside>
    );
};
export default MessageDialog;
