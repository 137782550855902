import React, { useState, useContext, useRef, useEffect } from 'react';

import themeContext from '../../../../../context/ThemeContext';

const Themes = ({ active, list, value }: any) => {
    const { setactivetheme, fetchThemeUpdate }: any = useContext(themeContext);
    // console.log(list);
    const [activetab, setactivetab] = useState(active);
    const verticalContainerRef = useRef<HTMLDivElement>(null);

    // const tabDisplay = (themeType: string) => {};

    const onChange2 = (value: any) => {
        setactivetheme(value);
        fetchThemeUpdate(value);
    };

    const sort = (a: any, b: any) => {
        return a.order - b.order;
    };

    useEffect(() => {
        const handleScroll = (event: WheelEvent) => {
            if (verticalContainerRef.current) {
                verticalContainerRef.current.scrollTop += event.deltaY;
                event.preventDefault();
            }
        };

        const containerElement = verticalContainerRef.current;

        if (containerElement) {
            containerElement.addEventListener('wheel', handleScroll, { passive: false });
        }

        return () => {
            if (containerElement) {
                containerElement.removeEventListener('wheel', handleScroll);
            }
        };
    }, []);

    return (
        <>
            <div className="flex flex-shink-0 c-s-p-tab me-tabs">
                <div
                    role="button"
                    className={activetab === 'general' ? 'active cur-pointer' : 'cur-pointer'}
                    onClick={() => setactivetab('general')}
                >
                    <span>General</span>
                </div>
                <div
                    role="button"
                    className={activetab === 'brand' ? 'active cur-pointer' : 'cur-pointer'}
                    onClick={() => setactivetab('brand')}
                >
                    <span>Brands</span>
                </div>
            </div>
            <div ref={verticalContainerRef} className="h-f overflow-hidden overflow-scroll-container">
                <div className="oveflow-scroll-content relative scroll-container">
                    <p className="fz12 my20">
                        Selecting a <strong>Theme</strong> will override any changes previously made.
                        {/* <br />
                {value} */}
                    </p>

                    <div className="w-full">
                        <ul className="c-p-t-list">
                            {list
                                .filter((z: any) => z.group === activetab)
                                .sort(sort)
                                .map(({ _id, name, background = '' }: any, index: number) => {
                                    const style =
                                        background !== ''
                                            ? {
                                                  background: `url('${background}') no-repeat 1px 1px #fff`,
                                              }
                                            : {};
                                    if (_id === value)
                                        return (
                                            <li
                                                key={`${_id}-${index}`}
                                                className="fz14 active"
                                                style={style}
                                                value={_id}
                                            >
                                                <div />
                                                {name}
                                            </li>
                                        );
                                    return (
                                        <li
                                            key={_id}
                                            className="cur-pointer cc2-cursor-pointer fz14"
                                            style={style}
                                            value={_id}
                                            onClick={() => onChange2(_id)}
                                        >
                                            <div />
                                            {name}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Themes;
