import React, { useEffect, useState, useContext, useRef } from 'react';

import languageContext from '../../../../context/languageContext';
import Shape from './Type/Shape';
import Color from './Type/Color';

import './design.css';
// import '../../../Panel/ShapeColor/shapeColor.css'

interface IDesignProps {
    colorpick?: (flag: boolean) => void;
}

const Design = ({ colorpick }: IDesignProps) => {
    const verticalContainerRef = useRef<HTMLDivElement>(null);
    const language: any = useContext(languageContext);

    // const [themeType] = useState('color');
    const elementsTab = {
        active: 1,
        tabs: [
            { id: 1, label: 'pillow_shapes' },
            { id: 2, label: 'pillow_colours' },
        ],
    };
    const [tabDisplay, setTabDisplay] = useState(elementsTab);

    useEffect(() => {
        const handleScroll = (event: WheelEvent) => {
            if (verticalContainerRef.current) {
                verticalContainerRef.current.scrollTop += event.deltaY;
                event.preventDefault();
            }
        };

        const containerElement = verticalContainerRef.current;

        if (containerElement) {
            containerElement.addEventListener('wheel', handleScroll, { passive: false });
        }

        return () => {
            if (containerElement) {
                containerElement.removeEventListener('wheel', handleScroll);
            }
        };
    }, []);
    // const activeTab = (e: string) => {};

    return (
        <section className="c-s-p-design flex flex-column h-f">
            <div className="flex  flex-shink-0 c-s-p-tab">
                {tabDisplay.tabs.map(({ label, id }: any) => {
                    return (
                        <div
                            key={id}
                            role="button"
                            className={`${tabDisplay.active !== id ? 'cur-pointer' : 'active'}`}
                            onClick={() => setTabDisplay((olddata: any) => ({ ...olddata, active: id }))}
                        >
                            <span>{language[label]}</span>
                        </div>
                    );
                })}
                {/* <div role='button' className={(themeType === 'color' ? 'active' : '')} onClick={() => activeTab('color')}>
                    <span>Shapes</span>
                </div>
                <div role='button' className={(themeType === 'themes' ? 'active' : '')} onClick={() => activeTab('themes')}>
                    <span>Colours</span>
                </div> */}
            </div>
            <div ref={verticalContainerRef} className="h-f overflow-hidden 0verflow-scroll-container">
                <div className="oveflow-scroll-content relative scroll-container">
                    {tabDisplay.active === 1 ? <Shape /> : <Color colorpick={colorpick} />}
                </div>
            </div>
        </section>
    );
};

export default Design;
