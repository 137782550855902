import React, { useState, Fragment } from 'react';

import { uploadFolderUpArrowIcon } from '../../../helpers/IconList';
import { displayFilename } from '../../../helpers/index';

type BackgroundUploadProps = {
    change: any;
    file?: any;
    children?: any;
    loader?: boolean;
    err?: any;
    uploadLimitexeed?: any;
    setUploadflag?: any;
};

const BackgroundUpload: React.FC<BackgroundUploadProps> = ({ change, uploadLimitexeed, setUploadflag }) => {
    // ERROR LIST WITH TYPE, TEXT AND ERR
    const EXTENSION_ALLOW = ['jpg', 'jpeg', 'gif', 'png'];
    const SIZE_ALLOW = 2048;
    // const RATIO_ALLOW = 16/9;
    const error40 = 'You have exceeded the maximum number of allowed images.';
    const [errorline, setErrorline] = useState<any>([]);
    const [errorList, setErrorList] = useState([
        {
            type: 'size',
            text: '- File size: <strong>max 2MB</strong>',
            err: false,
            msg: 'This file size of the selected image needs to be below 2 MB.',
        },
        {
            type: 'extension',
            text: '- File type: <strong>jpg, png or gif</strong>',
            err: false,
            msg: 'The file type of the selected image needs to be .jpg, .png or .gif',
        },
    ]);
    // { type: "dimension", text: "- File resolution: <b>1920 x 1080 (or 16 x 9 ratio)</b>", err: false, msg: "The file dimensions of the selected image need to be 1920px wide by 1080px high" },

    const [prev, setPrev] = useState<any>(null);
    const [image, setImage] = useState('');
    const [errFlag, setErrFlag] = useState(false);
    // FUNCTION AREA
    const handleFile = (e: any) => {
        const targetFile: any = e.target.files[0];

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            // console.warn(`File: read successfully`);
            const img: any = new Image();
            img.onload = function () {
                // const { width, height } = img;
                // const ratio = width / height;
                // if (ratio !== RATIO_ALLOW) {
                //     validation(targetFile, true);
                // } else
                // {
                setPrev(img.src);
                validation(targetFile, false);
                change(targetFile);
                // }
            };
            img.onerror = function () {
                //display error
                validation(targetFile, false);
                change('');
                setPrev(null);
                console.log('Image uploaded must be Image.');
            };

            img.src = reader.result;
        });
        if (targetFile !== undefined) reader.readAsDataURL(targetFile);
    };

    function validation(targetFile: any, dimflag: boolean) {
        if (dimflag) {
            //
        }
        if (targetFile !== undefined) {
            const { name, size } = targetFile;
            const fileSizeKB = Math.round(size / 1024);
            const scope_error = false;
            const er: any = [];

            setImage(name);

            const newErrList = errorList.map(({ type, err, ...obj }: any) => {
                if (scope_error) {
                    return { type, ...obj, err };
                }
                if (type === 'size' && SIZE_ALLOW <= fileSizeKB) {
                    // scope_error = true;
                    // setErrorline([obj.msg]);
                    er.push(obj.msg);
                    return { type, ...obj, err: true };
                }
                if (type === 'extension') {
                    const extension = name.split('.').pop().toLowerCase();
                    if (!EXTENSION_ALLOW.includes(extension)) {
                        // scope_error = true;
                        er.push(obj.msg);
                        // setErrorline(obj.msg);
                        return { type, ...obj, err: true };
                    }
                }
                // if (type === "dimension") {
                //     scope_error = dimflag;
                //     setErrorline(obj.msg);
                //     return {
                //         type, ...obj, err: dimflag
                //     }
                // }
                return { type, ...obj, err: false };
            });
            setErrorList(newErrList);
            setErrorline(er);

            // CHECK IF THERE IS ANY ERROR TO DISPLAY ERROR MESSAGE
            const flag = newErrList.some(({ err }: any) => {
                return err === true;
            });
            setErrFlag(flag);
            setUploadflag(flag);
        }
    }

    // FIRE CLICK ACTION IN INPUT TYPE FILE
    const action = () => {
        // const resetErrorList = errorList.map((x:any) => ({...x, err: false }));
        // console.log(resetErrorList);
        // setErrorList(resetErrorList);
        if (uploadLimitexeed === false) {
            document.getElementById('cc-upload-file')?.click();
        }
    };

    const errordisplay = () => (
        <p className="upload-err">
            {errorline.map((er: any, i: any) => (
                <span key={er + i}>{er}</span>
            ))}
        </p>
    );

    const actionDraw = () => {
        return (
            <Fragment>
                {uploadLimitexeed !== '' && <p className="upload-err">{error40}</p>}
                {errFlag && errordisplay()}
                <div className="upload-button-warp flex justify-center align-center">
                    <button className="upload-button" onClick={action}>
                        <img src={uploadFolderUpArrowIcon} alt="" />
                        <span> Select image file </span>
                    </button>
                    <input
                        type="file"
                        id="cc-upload-file"
                        onChange={handleFile}
                        accept="image/png, image/gif, image/jpeg"
                    />
                </div>
            </Fragment>
        );
    };
    const previewDraw = () => (
        <Fragment>
            {errFlag && errordisplay()}
            <div className="uploadPreview">
                <div className="flex1 textalign-center">
                    <img id="preview" src={prev} alt="preview" />
                </div>
                <div className="flex1 breakAll">
                    <div className="upload-filename">{displayFilename(image)}</div>
                    <div className="upload-button-warp flex justify-center align-center">
                        <button className="upload-button" onClick={action}>
                            <img src={uploadFolderUpArrowIcon} alt="" />
                            <span> Select image file </span>
                        </button>
                        <input
                            type="file"
                            id="cc-upload-file"
                            onChange={handleFile}
                            accept="image/png, image/gif, image/jpeg"
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );

    return (
        <div className="upload-form flex flex-column align-center">
            {prev !== null ? previewDraw() : actionDraw()}

            <div className="upload-requirements">
                <h4>Image requirements</h4>
                <ul>
                    {errorList.map(({ type, text, err }: any) => (
                        <li
                            key={type}
                            className={err === true ? 'upload-err' : ''}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    ))}
                </ul>
            </div>
            <div className="upload-note">
                We recommend to use a resolution of <strong>1920px by 1080px</strong> for the best results. Different
                resolutions can result in a lower image quality.
            </div>
        </div>
    );
};

export default BackgroundUpload;
